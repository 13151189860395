.app-header { background: $theme-black; padding: 13px 30px; border-bottom: 1px solid #444243; position: fixed; top: 0; left: 0; right: 0; z-index: 9999; }
.head-left { @extend .d-flex; @extend .align-items-center; }
.head-right { @extend .d-flex; @extend .align-items-center; @extend .justify-content-end; }
.header-option { 
  a { color: $white; display: inline-block; margin: 0 0 0 70px; font-size: 14px; font-family: 'Avenir-Medium';
    &:hover { color: $theme-black3; }
  }
}
.navbar-toggler { padding: 0; line-height: 1; background-color: rgba($color: #fff, $alpha: 0.10); border: 1px solid rgba(0, 0, 0, .1); border-radius: 5px; color: rgba(0, 0, 0, .5); width: 36px; height: 36px; display: none; }
.navbar-toggler-icon { background-image: url("./menu-white.png"); display: inline-block; width: 100%; height: 100%; vertical-align: middle; background-size: 100% 100%; }
.header-search { background: rgba($color: #ffffff, $alpha: .1); width: 400px; padding: 0; border-radius: 5px; margin-right: 20px; @extend .d-flex; position: relative;
  input { position: relative; width: calc(100% - 46px); margin: 0 0 0 46px; font-size: 14px;
    &:focus { z-index: 11; color: $theme-black;
      &::-webkit-input-placeholder { color: $theme-black; }
      &::-moz-placeholder { color: $theme-black; }
      &:-ms-input-placeholder { color: $theme-black; }
      &:-moz-placeholder { color: $theme-black; }
    }
    &:focus ~ button { img { opacity: 1; } }
    &:focus ~ .focus-close {
      img { opacity: 1; }
    }
    background: transparent; height: 36px; color: $white; width: calc(100% - 32px);
    &::-webkit-input-placeholder { color: rgba($color: #ffffff, $alpha: .75); font-family: $fonts-medium; font-size: 14px; }
    &::-moz-placeholder { color: rgba($color: #ffffff, $alpha: .75); font-family: $fonts-medium; font-size: 14px; }
    &:-ms-input-placeholder { color: rgba($color: #ffffff, $alpha: .75); font-family: $fonts-medium; font-size: 14px; }
    &:-moz-placeholder { color: rgba($color: #ffffff, $alpha: .75); font-family: $fonts-medium; font-size: 14px; }
  }
  button {
    padding: 0; min-width: unset; padding: 0 15px; background-repeat: no-repeat; background-position: 50% 50%; position: absolute; left: 0; top: 0; height: 100%;
    img { opacity: 0; }
    span { padding: 0; width: 16px; }
  }
}
.effect-bg ~ .focus-bg { position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-color: $white; opacity: 0; transition: 0.5s; z-index: -1; }
.effect-bg:focus ~ .focus-bg { transition: 0.5s; opacity: 1; border-radius: 5px;
  &::before { content: ''; position: absolute; right: 10px; top: 50%; transform: translateY(-50%); width: 18px; height: 18px; }
}
.focus-close { width: 16px; height: 16px; border-radius: 20px; position: absolute; top: 50%; transform: translateY(-50%); right: 10px; display: flex; cursor: pointer; z-index: 99;
  img { opacity: 0; }
}
.header-search-box { position: relative;
  .search-mobile-ui { display: none; }
}
.header-cart { padding: 0 15px; border-right: 1px solid $black; border-left: 1px solid $black;
  img { max-width: 25px; }
}
.header-status { padding: 0; position: relative; font-family: $fonts-sf; font-weight: 600;
  .logout-user-status { margin: 0 0 0 50px; background-position: 200% 200%; background-repeat: no-repeat; }
  .login-user-status { padding: 0 0 0 15px; }
  a { color: $white; }
  span { padding: 0 5px; color: $white; }
}

.login-user-status { @extend .d-flex-center-start; cursor: pointer; }
.login-user-img { margin: 0 15px 0 0; min-width: 30px; width: 30px; height: 30px; border-radius: 50%; overflow: hidden;
  img { width: 100%; height: 100%; object-fit: cover; }
}
.login-user-name { color: $white; font-size: 14px; }
.header-user-popup { min-width: 200px; position: absolute; right: 0; top: 40px; -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.5); -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.5); box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.5); display: none; color: $theme-black; background: $white; border-radius: 5px; overflow: hidden; z-index: 10;
  &.active { display: block; }
  .user-popup-icon { width: 30px; height: 30px; margin: 0 10px 0 0; background-color: $theme-black; border-radius: 5px; text-align: center; @extend .d-flex-center-center;
    img { max-width: 30px; }
  }
  ul { width: 100%; padding: 5px 0; }
  li { @extend .d-flex-center-start;  @include prefix( transition, all 0.3s ease-in-out);
    a { color: $theme-black; font-family: $fonts-extra; cursor: pointer; width: 100%;display: flex;align-items: center;padding: 10px;
      &:hover { background: $theme-color;color: $white;
      }
    }
    
  }
  & > a { background-color: #e8e6e6; width: 100%; padding: 8px 0; display: inline-block; text-align: center; color: $theme-black; font-family: $fonts-extra; cursor: pointer;
    &:hover { background-color: $theme-black; color: $white; }
  }
}

.header-search-popup { display: none; position: absolute; top: 44px; left: 0 ; width: 100%; background: $white; padding: 15px; -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.5); -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.5); box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.5); border-radius: 10px; z-index: 11;
  &.active { display: block; top: 44px; }
}
.legacy-search-list { @extend .d-flex-center-between; margin: 0; border-bottom: 1px solid #E8E6E6; height: 60px;
  &:first-child { padding-top: 0; }
  &:last-child { margin: 0; border: 0; padding-bottom: 0; }
}
.search-list-img { 
  display: flex;
  align-items: center;
  padding-right: 10px;
  img {width: 38px; min-width: 38px; height: 38px; border-radius: 5px; overflow: hidden; margin: 0 15px 0 0; }
}
.legacy-list-info {  padding: 10px 30px 10px 16px;;width: 100%; @extend .d-flex-center-between; color: $theme-black; font-size: 15px; @include prefix( transition, all 0.3s ease-in-out);
  span { @include prefix( transition, all 0.3s ease-in-out); font-family: $fonts-sf; font-weight: 400; }
  &:hover { color: $theme-color; background-color: #E8E6E6;}
  // img { max-width: 11px; }
}
.search-list-info {
  img { max-width: 11px; }
}


// new header start here

// .navbar-toggler-icon { background-image: url("data:image/svg+xml,%3Csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='grey' d='M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z'%3E%3C/path%3E%3C/svg%3E"); display: inline-block; width: 1.5em; height: 1.5em; vertical-align: middle; background-size: 100% 100%; }
ul.links-list { display: flex; flex-direction: row; }
li.nav-item { list-style-type: none; margin-left: 40px; }
nav a { text-decoration: none; }
// nav a.is-active { color: maroon; }

// new header over here

.scrollbar-container { height: 295px !important; }

.return-main-page { margin: 0 10px 0 0;
  a { border: 1px solid $theme-black5; border-radius: 10px; font-size: 14px; color: $white; padding: 9px 12px; line-height: 16px; @extend .d-flex-center-center; font-weight: 300;
    svg { margin: 0 6px 0 0; }
    &:hover { color: $theme-black; background: $white; border-color: $white; }
  }
}


@include responsive(xl) {
  .header-option a { margin: 0 0 0 30px; }
  .header-status .logout-user-status { margin: 0 0 0 20px; }
}


@include responsive(lg) {
  .app-header { padding: 13px 15px; }
  .header-search { margin-right: 10px; width: calc(100% - 30px); padding: 4px 10px; position: fixed; right: 15px; top: 60px; z-index: 99; background: $theme-black; box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5); opacity: 0; margin: 0; display: none;
    &.active { opacity: 1; display: block; }
    button { margin: 0; }
  }
  .header-search-popup { min-width: 280px; left: inherit; right: 0;
    &.active { top: 47px; border-radius: 5px; padding: 5px; }
  }
  .header-search input { margin: 0 0 0 36px; }
  .header-search-box {
    .search-mobile-ui { display: block; background: rgba(255, 255, 255, 0.1); width: 36px; min-width: 36px; height: 36px; margin-right: 10px; line-height: 20px; }
  }
  .header-cart { padding: 0; border: 0; background: rgba(255, 255, 255, .1); border-radius: 5px; padding: 6px 6px 7px 6px; }
  .head-right { padding: 0 46px 0 0; }
  .header-option { position: absolute; right: 15px;
    a {  padding: 10px 30px; display: block; margin: 0; } }
  .navbar-toggler { display: block; padding: 6px; cursor: pointer; }
  .navbar-links { margin-right: 0; position: fixed; right: 0; width: 100%; background: $theme-black; z-index: 99; margin-top: 11px; padding: 10px 0; transition: all ease-in-out 0.3s; }
  .navbar-links.menu-collapse { margin-right: -100%; width: 0; overflow: hidden; }
  .menu-collapse { margin-right: -75%; width: 0; overflow: hidden; }
  ul.links-list { display: flex; flex-direction: column; padding-left: 0; }
  li.nav-item { padding-left: 20px; padding-top: 10px; padding-bottom: 10px; margin-left: 0; }
  li.nav-item a.nav-link { padding: 10px 20px; }
  .header-status { width: 36px; height: 36px; border-radius: 5px; background: rgba($color: #fff, $alpha: 0.1); padding: 0; margin: 0 0 0 10px;
    .login-user-status { padding: 3px; height: 36px;}
    .logout-user-status { width: 36px; height: 36px; background-repeat: no-repeat; background-position: 50% 50%; padding: 6px; cursor: pointer; margin: 0;
      .logout-desktop { position: absolute; top: 47px; background: #fff; right: 0; padding: 10px 20px; border-radius: 0 0 5px 5px; min-width: 184px; box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5); text-align: center; display: none; z-index: 99;
        &.active { display: block; }
        a, span { color: $theme-black; font-family: $fonts-medium; }
      }
    }
  }
}

@include responsive(md) {
  .app-header { padding: 10px 15px; }
  
  .logo-box { max-width: 100px; }
  .login-user-name { display: none; }
  .login-user-img { margin: 0; max-width: 100%; }
  .legacy-list-info { padding: 10px; }
}

.cart-item-number{color: white; margin:3px}

@include responsive(sm) {
  .head-left { -ms-flex-pack:justify; -webkit-box-pack:justify; justify-content:space-between; }
  .header-option {
    a { margin: 0 0 0 15px; }
  }
  .return-main-page {
    a { padding: 0; width: 36px; height: 36px; background: rgba($color: $white, $alpha: 0.1); border-radius: 4px;
      span { display: none; }
      svg { margin: 0; }
    }
  }
}


@include responsive(xxs) {
  .header-status { margin: 0 0 0 6px; width: 33px; height: 33px;
    .logout-user-status { width: 33px; min-width: 33px; height: 33px; }
  }
  .header-cart { width: 33px; min-width: 33px; height: 33px;
    a { display: flex; align-items: center; justify-content: center; }
  }
  .return-main-page {
    a { width: 33px; height: 33px; }
  }
  .header-cart, .navbar-toggler { width: 33px; height: 33px; }
  .header-search-box .search-mobile-ui { margin-right: 6px; width: 33px; min-width: 33px; height: 33px; }
  .head-right { padding: 0 39px 0 0; }
  .logo-box { max-width: 84px; }
}