@import "mixin";


/*--- Row ---*/
.row {display:-ms-flexbox;display:-webkit-box;display:flex;-ms-flex-wrap:wrap;-webkit-flex-wrap:wrap;flex-wrap:wrap; margin-right: -15px; margin-left: -15px; }

.no-gutters {margin-right: 0 !important; margin-left: 0 !important; }
.no-gutters > [class^="col-"], .no-gutters > [class^="column-"] {padding-right: 0; padding-left: 0; }

[class^="col-"] {position: relative; min-height: 1px; padding-right: 15px; padding-left: 15px; -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%;}
[class^="column"] {position: relative; min-height: 1px; padding-right: 15px; padding-left: 15px;}

.row.space-18 {margin-left: -9px;margin-right: -9px;}
.row.space-18 [class^="col-"],.row.space-18 [class^="column-"] {padding-right: 9px;padding-left: 9px;}
.row.padding-b30 {
    [class^="col-"], [class^="column"] { padding-bottom: 30px; }
}

.column-one {-ms-flex: 0 0 306px; flex: 0 0 306px; max-width: 306px;}
.column-two {-ms-flex: 0 0 204px; flex: 0 0 204px; max-width: 204px;}
.column-three {-ms-flex: 0 0 408px; flex: 0 0 408px; max-width: 408px;}

.col-1 {-ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
.col-2 {-ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
.col-3 {-ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
.col-4 {-ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
.col-5 {-ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
.col-6 {-ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
.col-7 {-ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
.col-8 {-ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
.col-9 {-ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
.col-10 {-ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
.col-11 {-ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
.col-12 {-ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }

@media (min-width: 576px) {
    .col-sm-1 {-ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
    .col-sm-2 {-ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
    .col-sm-3 {-ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
    .col-sm-4 {-ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
    .col-sm-5 {-ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
    .col-sm-6 {-ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
    .col-sm-7 {-ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
    .col-sm-8 {-ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
    .col-sm-9 {-ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
    .col-sm-10 {-ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
    .col-sm-11 {-ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
    .col-sm-12 {-ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
}

@media (min-width: 768px) {
    .col-md-1 {-ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
    .col-md-2 {-ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
    .col-md-3 {-ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
    .col-md-4 {-ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
    .col-md-5 {-ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
    .col-md-6 {-ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
    .col-md-7 {-ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
    .col-md-8 {-ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
    .col-md-9 {-ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
    .col-md-10 {-ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
    .col-md-11 {-ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
    .col-md-12 {-ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
}

@media (min-width: 992px) {
    .col-lg-1 {-ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
    .col-lg-2 {-ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
    .col-lg-3 {-ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
    .col-lg-4 {-ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
    .col-lg-5 {-ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
    .col-lg-6 {-ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
    .col-lg-7 {-ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
    .col-lg-8 {-ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
    .col-lg-9 {-ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
    .col-lg-10 {-ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
    .col-lg-11 {-ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
    .col-lg-12 {-ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
}

@media (min-width: 1200px) {
    .col-xl-1 {-ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
    .col-xl-2 {-ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
    .col-xl-3 {-ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
    .col-xl-4 {-ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
    .col-xl-5 {-ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
    .col-xl-6 {-ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
    .col-xl-7 {-ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
    .col-xl-8 {-ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
    .col-xl-9 {-ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
    .col-xl-10 {-ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
    .col-xl-11 {-ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
    .col-xl-12 {-ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
}

@include responsive(md) {
    .column-three {-ms-flex: 0 0 308px;flex: 0 0 308px;}
    .column-two {-ms-flex: 0 0 180px;flex: 0 0 180px;max-width: 180px;}
    .row.padding-b30 {
        [class^="col-"], [class^="column"] {
            padding-bottom: 20px;
        }
    }
}
@include responsive(sm) {
    .col-300px {-ms-flex: 0 0 320px;flex: 0 0 320px;max-width: 320px;}
}