@import "variable";


// Fonts Size
h1 { font-size: 45px; }
h2 { font-size: 26px;
  .big { font-size: 32px; }
}
h3 { font-size: 22px; }
h4 { font-size: 18px; }
h5 { font-size: 16px; }
p { font-size: 16px;
  &.small { font-size: 14px; }
}
.text-center { text-align: center; }
.text-right { text-align: right; }
.text-success { color: $success; }
.text-danger { color: $danger; }

.text-warning { color: $warning; }
.text-info { color: $info; }
.text-minute { color: $color2; }
.position-relative { position: relative; }
// Font Family


// Width css
.w-100 { width: 100%; }
// Height SCSS
.min-h100 { min-height: 100vh; }
.h-100 { height: 100%; }
.container { width: 1110px; max-width: 100%; padding-left: 15px; padding-right: 15px; margin: 0 auto;
  &.lg { width: 1232px; }
  &.md { width: 908px; }
}
.container-fluid { width: 100%; padding: 0 15px; }

a,button {box-shadow: none !important;}

// Responsive
@include responsive(md) { // Fonts Size
  h1 { font-size: 30px; }
  h2 { font-size: 22px;
    &.big { font-size: 28px; }
  }
  h3 { font-size: 19px; }
  h4 { font-size: 17px; }
  h5 { font-size: 15px; }
  p { font-size: 15px;
    &.small { font-size: 13px; }
  }
  body { font-size: 15px; color: $theme-black; }
}